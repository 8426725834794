.farm_activities {
  select {
    padding: 5px 5px 5px 10px;
    border: 1px solid #e8e8e8;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.65);
    width: 80%;
    max-width: 100%;
    border-radius: 5px;
  }
}

.activitycard ul {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #e8e8e8;

  li {
    padding: 10px;
  }
}

.activity-card {
  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #6c757d;
  }

  .activity-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #6c757d;
    margin-top: 10px;
  }

  .activities {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: #6c757d;
    margin-top: 5px;
  }
}

.map-card {
  padding: 20px 0;
}
