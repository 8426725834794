@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,700;0,800;1,400;1,600;1,700;1,800&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,800;1,400;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

* {
  font-family: Nunito, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

html{
  --lazy-light-color: #fcfcfc;
  --lazy-dark-color: #f6f6f6;
}

body {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lazy-loading{
  background-color: transparent;
  animation: lazy-loading 1.2s ease-in-out 0s infinite alternate both;
  transition: all 50ms linear;
}

@-webkit-keyframes lazy-loading{
  0%, 60%, 70%, 80%, 90%, 100%{
      background-image: -webkit-linear-gradient(var(--lazy-dark-color), var(--lazy-dark-color));
      background-image: linear-gradient(var(--lazy-dark-color), var(--lazy-dark-color));
  }

  15%{
      background-image: -webkit-linear-gradient(135deg, var(--lazy-dark-color) 10%,  var(--lazy-light-color) 25%, var(--lazy-dark-color) 40%);
      background-image: linear-gradient(135deg, var(--lazy-dark-color) 10%,  var(--lazy-light-color) 25%, var(--lazy-dark-color) 40%);
  }

  30%{
      background-image: -webkit-linear-gradient(135deg, var(--lazy-dark-color) 35%,  var(--lazy-light-color) 50%, var(--lazy-dark-color) 65%);
      background-image: linear-gradient(135deg, var(--lazy-dark-color) 35%,  var(--lazy-light-color) 50%, var(--lazy-dark-color) 65%);
  }

  55%{
      background-image: -webkit-linear-gradient(135deg, var(--lazy-dark-color) 60%,  var(--lazy-light-color) 75%, var(--lazy-dark-color) 90%);
      background-image: linear-gradient(135deg, var(--lazy-dark-color) 60%,  var(--lazy-light-color) 75%, var(--lazy-dark-color) 90%);
  }
}

@-moz-keyframes lazy-loading{
  0%, 60%, 70%, 80%, 90%, 100%{
      background-image: -webkit-linear-gradient(var(--lazy-dark-color), var(--lazy-dark-color));
      background-image: linear-gradient(var(--lazy-dark-color), var(--lazy-dark-color));
  }

  15%{
      background-image: -webkit-linear-gradient(135deg, var(--lazy-dark-color) 10%,  var(--lazy-light-color) 25%, var(--lazy-dark-color) 40%);
      background-image: linear-gradient(135deg, var(--lazy-dark-color) 10%,  var(--lazy-light-color) 25%, var(--lazy-dark-color) 40%);
  }

  30%{
      background-image: -webkit-linear-gradient(135deg, var(--lazy-dark-color) 35%,  var(--lazy-light-color) 50%, var(--lazy-dark-color) 65%);
      background-image: linear-gradient(135deg, var(--lazy-dark-color) 35%,  var(--lazy-light-color) 50%, var(--lazy-dark-color) 65%);
  }

  55%{
      background-image: -webkit-linear-gradient(135deg, var(--lazy-dark-color) 60%,  var(--lazy-light-color) 75%, var(--lazy-dark-color) 90%);
      background-image: linear-gradient(135deg, var(--lazy-dark-color) 60%,  var(--lazy-light-color) 75%, var(--lazy-dark-color) 90%);
  }
}

@keyframes lazy-loading{
  0%, 60%, 70%, 80%, 90%, 100%{
      background-image: -webkit-linear-gradient(var(--lazy-dark-color), var(--lazy-dark-color));
      background-image: linear-gradient(var(--lazy-dark-color), var(--lazy-dark-color));
  }

  15%{
      background-image: -webkit-linear-gradient(135deg, var(--lazy-dark-color) 10%,  var(--lazy-light-color) 25%, var(--lazy-dark-color) 40%);
      background-image: linear-gradient(135deg, var(--lazy-dark-color) 10%,  var(--lazy-light-color) 25%, var(--lazy-dark-color) 40%);
  }

  30%{
      background-image: -webkit-linear-gradient(135deg, var(--lazy-dark-color) 35%,  var(--lazy-light-color) 50%, var(--lazy-dark-color) 65%);
      background-image: linear-gradient(135deg, var(--lazy-dark-color) 35%,  var(--lazy-light-color) 50%, var(--lazy-dark-color) 65%);
  }

  55%{
      background-image: -webkit-linear-gradient(135deg, var(--lazy-dark-color) 60%,  var(--lazy-light-color) 75%, var(--lazy-dark-color) 90%);
      background-image: linear-gradient(135deg, var(--lazy-dark-color) 60%,  var(--lazy-light-color) 75%, var(--lazy-dark-color) 90%);
  }
}
